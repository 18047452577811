import { defineMessages } from "react-intl";

const prefix = "setupWizard.revenuePageForm.";

export const messages = defineMessages({
  companyRevenuePlaceholder: {
    id: `${prefix}companyRevenuePlaceholder`,
    defaultMessage: "What's your estimated revenue for this year?",
    description: "Company revenue for the setup wizard",
  },
  companyRevenueError: {
    id: `${prefix}companyRevenueError`,
    defaultMessage: "Please tell us your estimated annual revenue",
    description: "Company revenue error for the setup wizard",
  },
  companyRevenue0To50K: {
    id: `${prefix}companyRevenue0To50K`,
    defaultMessage: "$0 - $50K",
    description: "Company revenue '$0 - $50,000' option for the setup wizard",
  },
  companyRevenue50To150K: {
    id: `${prefix}companyRevenue50To150K`,
    defaultMessage: "$50K - $150K",
    description:
      "Company revenue '$50,000 - $150,000' option for the setup wizard",
  },
  companyRevenue150To500K: {
    id: `${prefix}companyRevenue150To500K`,
    defaultMessage: "$150K - $500K",
    description:
      "Company revenue '$150,000 - $500,000' option for the setup wizard",
  },
  companyRevenue500KPlus: {
    id: `${prefix}companyRevenue500KPlus`,
    defaultMessage: "$500K+",
    description: "Company revenue '$500,000+' option for the setup wizard",
  },
  companyRevenuePreferNotToSay: {
    id: `${prefix}companyRevenuePreferNotToSay`,
    defaultMessage: "I'd prefer not to say",
    description:
      "Company revenue 'I'd prefer not to say' option for the setup wizard",
  },
});
