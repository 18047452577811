import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "reviews.reviewSettings.title",
    defaultMessage: "Manage",
    description: "Title for the side drawer",
  },
  generalError: {
    id: "reviews.reviewSettings.generalError",
    defaultMessage: "Something went wrong. Please try again later.",
    description:
      "Description for handling a problem with Jobber at a later time",
  },
  deliveryMethodError: {
    id: "reviews.reviewSettings.deliveryMethodError",
    defaultMessage: "Failed to load existing delivery method.",
    description:
      "Description for handling a problem with Jobber at a later time",
  },
  scheduledTimeOffsetError: {
    id: "reviews.reviewSettings.scheduledTimeOffsetError",
    defaultMessage:
      "Failed to load the scheduled follow-up times. Please try again.",
    description: "Description for error loading existing scheduled times",
  },
  followUpError: {
    id: "reviews.reviewSettings.followUpError",
    defaultMessage: "Failed to load follow-up scheduled times",
    description: "Description for error loading follow-up scheduled times",
  },
  template: {
    id: "reviews.reviewSettings.template",
    defaultMessage: "Templates",
    description: "Title for the template section",
  },
  messageTitle: {
    id: "reviews.reviewSettings.message",
    defaultMessage: "Message",
    description: "Title for the message section",
  },
  messageDescription: {
    id: "reviews.reviewSettings.messageDescription",
    defaultMessage:
      "Automatically ask for a review after the client has paid their invoice",
    description: "Description for the message section",
  },
  followUpsTitle: {
    id: "reviews.reviewSettings.followUps",
    defaultMessage: "Follow-ups",
    description: "Title for the follow-ups section",
  },
  initialFollowUp: {
    id: "reviews.reviewSettings.initialFollowUp",
    defaultMessage:
      "Send {offsetValue} {offsetUnit} after at {at} if the review link has not been used",
    description: "Title for the initial follow-up section",
  },
  secondaryFollowUp: {
    id: "reviews.reviewSettings.secondaryFollowUp",
    defaultMessage:
      "Send {offsetValue} {offsetUnit} after at {at} if the review link has not been used",
    description: "Title for the secondary follow-up section",
  },
  accounts: {
    id: "reviews.reviewSettings.accounts",
    defaultMessage: "Accounts",
    description: "Title for the account section",
  },
  accountsDescription: {
    id: "reviews.reviewSettings.accountsDescription",
    defaultMessage: "Google Business Profile",
    description: "Description for the accounts section",
  },
  disconnect: {
    id: "reviews.reviewSettings.disconnect",
    defaultMessage: "Disconnect",
    description: "Label for the disconnect button",
  },
  confirmDisconnectTitle: {
    id: "reviews.reviewSettings.confirmDisconnectTitle",
    defaultMessage: "Disconnect account?",
    description: "Title for the confirm disconnect modal",
  },
  confirmDisconnectDescription: {
    id: "reviews.reviewSettings.confirmDisconnectDescription",
    defaultMessage:
      "By disconnecting your Google Business Profile, you will no longer see your reviews on your dashboard and your clients will no longer be sent review requests",
    description: "Description for the confirm disconnect modal",
  },
  commsSettingsTitle: {
    id: "reviews.commsSettings.title",
    defaultMessage: "Message",
    description: "Title for the comms setting drawer",
  },
  bothOptionsLabel: {
    id: "reviews.commsSettings.bothOptionsLabel",
    defaultMessage: "Text and email",
    description: "Label for both options in the comms setting drawer",
  },
  emailOptionLabel: {
    id: "reviews.commsSettings.emailOptionLabel",
    defaultMessage: "Email",
    description: "Label for email option in the comms setting drawer",
  },
  textOptionLabel: {
    id: "reviews.commsSettings.textOptionLabel",
    defaultMessage: "Text",
    description: "Label for text option in the comms setting drawer",
  },
  messageSettingsAriaLabel: {
    id: "reviews.commsSettings.messageSettingsAriaLabel",
    defaultMessage: "Edit message settings",
    description: "Aria label for the message settings button",
  },
  messageSettingsBackAriaLabel: {
    id: "reviews.commsSettings.messageSettingsBackAriaLabel",
    defaultMessage: "Back to main settings",
    description: "Aria label for the back button in the message settings",
  },
  firstFollowUpMessageSettingsAriaLabel: {
    id: "reviews.commsSettings.firstFollowUpMessageSettingsAriaLabel",
    defaultMessage: "Edit first follow-up message settings",
    description: "Aria label for the first follow-up message settings button",
  },
  secondFollowUpMessageSettingsAriaLabel: {
    id: "reviews.commsSettings.secondFollowUpMessageSettingsAriaLabel",
    defaultMessage: "Edit second follow-up message settings",
    description: "Aria label for the second follow-up message settings button",
  },
  messageSettingsPlaceholder: {
    id: "reviews.commsSettings.messageSettingsPlaceholder",
    defaultMessage: "Send by",
    description: "Placeholder for the message settings dropdown",
  },
  messageSettingsSelectName: {
    id: "reviews.commsSettings.messageSettingsSelectName",
    defaultMessage: "deliveryMethod",
    description: "Name for the message settings dropdown",
  },
  messageSettingsSaveAriaLabel: {
    id: "reviews.commsSettings.messageSettingsSaveAriaLabel",
    defaultMessage: "Save message settings",
    description: "Aria label for the save button in the message settings",
  },
  messageSettingsSaveLabel: {
    id: "reviews.commsSettings.messageSettingsSaveLabel",
    defaultMessage: "Save",
    description: "Label for the save button in the message settings",
  },
  messageSettingsSaveSuccess: {
    id: "reviews.commsSettings.messagesSettingsSaveSuccess",
    defaultMessage: "Saved Template",
    description: "Success message for saving the message settings",
  },
  followUpTitle: {
    id: "reviews.commsSettings.followUpTitle",
    defaultMessage: "Follow-up",
    description: "Title for the follow-up setting drawer",
  },
  followUpHeading: {
    id: "reviews.commsSettings.followUpHeading",
    defaultMessage: "Follow-up with client",
    description: "Title for the reviews follow up settings heading",
  },
});
