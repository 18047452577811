import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import {
  Autocomplete,
  Button,
  Content,
  Form,
  InputText,
  InputValidation,
} from "@jobber/components";
import type { Option } from "@jobber/components/Autocomplete";
import type { Industry } from "~/utilities/API/graphql";
import { Routes } from "jobber/setupWizard/types";
import type {
  IndustryCategory,
  SetupWizardStepProps,
} from "jobber/setupWizard/types";
import { SetupWizardContext } from "jobber/setupWizard/context";
import { generateLastSubmittedStepQandA } from "jobber/setupWizard/utils";
import { messages } from "./messages";
import styles from "../../SetupWizardForms.module.css";

export function CompanyPageForm({
  navigation,
  industryMap,
  onMobileWeb,
}: SetupWizardStepProps) {
  const { formatMessage } = useIntl();
  const { wizardData, updateWizardData, saving } =
    useContext(SetupWizardContext);
  const [companyName, setCompanyName] = useState<string>(
    wizardData.companyName ?? "",
  );
  const [industry, setIndustry] = useState<Option | undefined>(
    getHeadingOptions(industryMap)
      .flatMap(option => option.options)
      .find(option => option.value === wizardData?.industry),
  );

  const [showValidationErrors, setShowValidationErrors] =
    useState<boolean>(false);

  const handleSubmit = async () => {
    if (!companyName || !industry) {
      if (!showValidationErrors) {
        setShowValidationErrors(true);
      }
      return;
    }

    const dataToSend = {
      companyName,
      industry: industry?.value as Industry | undefined,
      questionsAndAnswers: [generateLastSubmittedStepQandA(Routes.company)],
    };

    updateWizardData(dataToSend, navigation.isFinalStep, navigation.onNext);
  };

  const handleCompanyNameChange = (name: string) => {
    setCompanyName(name);
  };

  const handleIndustryChange = (selectedIndustry: Option) => {
    setIndustry(selectedIndustry);
    updateWizardData(
      { industry: selectedIndustry?.value as Industry | undefined },
      false,
      undefined,
      true,
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Content spacing="large">
        <Content spacing="minuscule">
          <InputText
            name="companyName"
            placeholder={formatMessage(messages.companyNamePlaceholder)}
            value={companyName}
            onChange={handleCompanyNameChange}
            size="large"
            invalid={!companyName && showValidationErrors}
          />
          {!companyName && showValidationErrors && (
            <InputValidation
              message={formatMessage(messages.companyNameError)}
            />
          )}
        </Content>
        <Content spacing="minuscule">
          <Autocomplete
            allowFreeForm={false}
            clearable={"while-editing"}
            initialOptions={getHeadingOptions(industryMap)}
            value={industry}
            name="industry"
            placeholder={formatMessage(messages.industryPlaceholder)}
            onChange={handleIndustryChange}
            size="large"
            getOptions={text => getOptions(text, industryMap)}
            invalid={!industry && showValidationErrors}
          />
          {!industry && showValidationErrors && (
            <InputValidation message={formatMessage(messages.industryError)} />
          )}
        </Content>
        <div className={styles.button}>
          <Button
            id={"companyPageSubmit"}
            fullWidth={onMobileWeb}
            label={navigation.nextCtaCopy}
            submit={true}
            loading={saving}
            size="large"
          />
        </div>
      </Content>
    </Form>
  );
}

const getHeadingOptions = (industryMap: IndustryCategory[]) => {
  return industryMap.map(obj => {
    return {
      label: obj.category,
      options: obj.industries.map(industry => ({
        value: industry.industryEnum,
        label: industry.displayName,
      })),
    };
  });
};

function getOptions(text: string, industryMap: IndustryCategory[]): Option[] {
  const headingOptions = getHeadingOptions(industryMap);

  if (text === "") {
    return headingOptions;
  }

  const filterRegex = new RegExp(text, "i");

  return headingOptions.map(section => ({
    ...section,
    options: section.options.filter(option => option.label.match(filterRegex)),
  }));
}
