import React from "react";
import { act, render, screen, waitFor, within } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { MockedProvider } from "@apollo/client/testing";
import type { MockedResponse } from "@apollo/client/testing";
import { OPEN_ASSISTANT_FROM_INSIGHT_WIDGET } from "~/jobber/insightWidgets/constants";
import { JobberAssistantRoot } from "./JobberAssistantRoot";
import {
  buildAiAssistantPromptMock,
  buildMockLastConversationQueryRecent,
  buildSuccessfulLoadMock,
  buildSuccessfulSubscriptionInitializeMock,
} from "./components/mocks";
import { OPEN_ASSISTANT_FROM_BUTTON } from "./constants";

export class JobberAssistantPOM {
  private constructor() {
    // private constructor as we want the renderPage static method to be used
  }

  public static renderPageWithQueryMocks(mocks: MockedResponse[]) {
    render(
      <MockedProvider mocks={mocks}>
        <JobberAssistantRoot />
      </MockedProvider>,
    );

    return new this();
  }

  public static renderPage() {
    const successfulResponseMocks = [
      buildMockLastConversationQueryRecent(
        "f0115064-5867-4dfd-93eb-ce0bca429b6b",
        "f0115064-5867-4dfd-93eb-ce0bca429b6b",
      ),
      buildSuccessfulSubscriptionInitializeMock(
        "f0115064-5867-4dfd-93eb-ce0bca429b6b",
      ),
      buildSuccessfulSubscriptionInitializeMock("mockUuid-1"),
      buildSuccessfulLoadMock(
        "f0115064-5867-4dfd-93eb-ce0bca429b6b",
        "mockUuid-1",
      ),
      buildSuccessfulLoadMock("mockUuid-1", "mockUuid-2"),
      buildAiAssistantPromptMock(
        "mockUuid-5",
        "f0115064-5867-4dfd-93eb-ce0bca429b6b",
        "prompt test",
      ),
      buildAiAssistantPromptMock(
        "mockUuid-5",
        "mockUuid-1",
        "test",
        "insight-widget-name",
      ),
    ];

    return JobberAssistantPOM.renderPageWithQueryMocks(successfulResponseMocks);
  }

  async findGraphAxesByLabels(axes: string[]) {
    for (const axis of axes) {
      // eslint-disable-next-line jest/no-standalone-expect
      expect(await screen.findByText(axis)).toBeInTheDocument();
    }

    return true;
  }

  async assertAllGraphAxesMissing(axes: string[]) {
    for (const axis of axes) {
      // eslint-disable-next-line jest/no-standalone-expect
      expect(screen.queryByText(axis)).toBeNull();
    }

    return true;
  }

  findGraphAxis(axis: string) {
    return screen.findByText(axis);
  }

  findGraphTitle(title: string) {
    return screen.findByText(title);
  }

  public async sendButton(): Promise<HTMLElement> {
    return screen.findByLabelText(/Send/s);
  }

  public get chatPanel(): Promise<HTMLElement> {
    return this._chatPanel();
  }

  public get drawerIsLoading(): boolean {
    return !!screen.queryByTestId("loading-spinner");
  }

  public get assistantButton(): HTMLElement {
    return this.component.getByLabelText("Open Jobber Copilot");
  }

  public get drawerHeader(): HTMLElement | null {
    return screen.queryByText("Copilot");
  }

  public get messageWithImage(): Promise<HTMLElement> {
    return screen.findByTestId("link-preview-image");
  }

  public get wasThatHelpfulMessage(): HTMLElement | null {
    return screen.queryByText("Was that helpful?");
  }

  public get feedbackThumbsUpButton(): HTMLElement | null {
    return screen.queryByRole("button", { name: "Yes" });
  }

  public get feedbackThumbsDownButton(): HTMLElement | null {
    return screen.queryByRole("button", { name: "No" });
  }

  public get supportFollowupMessage(): Promise<HTMLElement> {
    return screen.findByText("Did that answer your question?");
  }

  public get thatHelpedPill(): Promise<HTMLElement> {
    return screen.findByRole("button", { name: "That helped. 👍" });
  }

  public get connectWithSupportPill(): Promise<HTMLElement> {
    return screen.findByRole("button", { name: "Connect with support. 👤" });
  }

  public get feedbackSubmittedMessage(): Promise<HTMLElement> {
    return screen.findByText("Thanks for your feedback");
  }

  public get feedbackErrorMessage(): Promise<HTMLElement> {
    return screen.findByText(
      "There was an error saving your feedback. Please try again.",
    );
  }

  public get chatWithSuccessButton(): Promise<HTMLElement> {
    return screen.findByRole("button", { name: "Chat with Success" });
  }

  public triggerInsightClick(): void {
    act(() => {
      const event = new CustomEvent(OPEN_ASSISTANT_FROM_INSIGHT_WIDGET, {
        detail: { message: "test", widgetName: "insight-widget-name" },
      });
      window.dispatchEvent(event);
    });
  }

  public openDrawer(): void {
    act(() => {
      window.dispatchEvent(
        new CustomEvent(OPEN_ASSISTANT_FROM_BUTTON, {
          detail: { source: "top-nav" },
        }),
      );
    });
  }

  public closeDrawer() {
    userEvent.click(screen.getByTestId("cross"));
  }

  public writeAndSendPrompt(prompt: string) {
    const input = screen.getByRole("textbox");
    userEvent.type(input, prompt);
    const button = screen.getByRole("button", { name: "Send" });
    userEvent.click(button);
  }

  public get sendButtonIsDisabled(): boolean {
    return screen
      .getByRole("button", { name: "Send" })
      .hasAttribute("disabled");
  }

  public debug() {
    screen.debug();
  }

  private async _chatPanel() {
    await this.waitForDrawerToLoad();
    return screen.getByTestId("jobber-assistant-chat");
  }

  private async waitForDrawerToLoad(): Promise<void> {
    // it's OK to use expect, as this is used in tests
    // eslint-disable-next-line jest/no-standalone-expect
    return waitFor(() => expect(this.drawerIsLoading).toBe(false));
  }

  private get component() {
    const root = screen.getByTestId("jobber-assistant");
    return within(root);
  }
}
