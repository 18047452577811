import { useMutation } from "@apollo/client";
import type { UpdateNotificationMutation } from "~/utilities/API/graphql";
import { UPDATE_NOTIFICATION_MUTATION } from "../ReviewsPage.graphql";
import type { UpdateNotificationArgs } from "../types";

export function useUpdateNotification() {
  const [editMutation] = useMutation<UpdateNotificationMutation>(
    UPDATE_NOTIFICATION_MUTATION,
  );

  async function updateNotification({
    deliveryMethod,
    clientNotificationId,
    scheduleId,
    templateId,
    message,
    subject,
    scheduleEnabled,
  }: UpdateNotificationArgs) {
    const schedulesBody = scheduleId
      ? {
          id: scheduleId,
          deliveryMethod: deliveryMethod?.toUpperCase(),
          enabled: scheduleEnabled,
        }
      : undefined;

    const templatesBody = templateId
      ? {
          id: templateId,
          message,
          subject,
        }
      : undefined;

    const payload = {
      variables: {
        id: clientNotificationId,
        data: {
          schedules: schedulesBody,
          templates: templatesBody,
        },
      },
    };

    try {
      const result = await editMutation(payload);
      const userErrors = result.data?.updateNotification.errors ?? [];
      return userErrors;
    } catch (e) {
      return [e];
    }
  }

  return {
    updateNotification,
  };
}
