import type { FetchResult } from "@apollo/client";
import type { Industry } from "~/utilities/API/graphql";
import type { SPQuoteDetails } from "./components/SPQuote";

export enum Routes {
  business = "/business",
  community = "/community",
  company = "/company",
  hdyhau = "/heard_about_us",
  profile = "/profile",
  promotion = "/promotion",
  revenue = "/revenue",
  topPriority = "/top_priority",
}

export const RouteToContentKeyMap: {
  [key in Routes]?: keyof IndustrySpecificContentType;
} = {
  [Routes.business]: "businessPage",
  [Routes.community]: "communityPage",
  [Routes.hdyhau]: "heardAboutUsPage",
  [Routes.profile]: "profilePage",
  [Routes.revenue]: "revenuePage",
  [Routes.topPriority]: "topPriorityPage",
};

export interface ImageType {
  src: string;
  alt: MessageFormat;
}

export interface IndustrySpecificContentType {
  [key: string]: {
    backgroundImage: ImageType;
    heading?: MessageFormat;
    quote?: SPQuoteDetails;
  };
}

export interface IndustryCategory {
  category: string;
  industries: { displayName: string; industryEnum: Industry }[];
}

export interface PromoDetails {
  welcomeText: string;
  title: string;
  information: string;
  subtext: string;
  buttonText: string;
}

export interface SignupConsentData {
  hasData: boolean;
  consentRegion: string;
}

export interface SetupWizardStepProps {
  navigation: {
    isFinalStep: boolean;
    nextCtaCopy: string;
    onNext: () => void;
  };
  industryMap: IndustryCategory[];
  onMobileWeb: boolean;
  promoDetails: PromoDetails;
  promoTrackingTag: string;
  showPromoStatusQuestionKey: string;
  showPromoStatusInterestedValue: string;
  signupConsentData: SignupConsentData;
  attrName: string;
}

export interface SetupWizardStep {
  route: Routes;
  component: React.ElementType<SetupWizardStepProps>;
  includeInProgress: boolean;
}

export interface SetupWizardData {
  accountOwnerName?: string;
  companyName?: string;
  companySize?: string;
  email?: string;
  heardAboutUs?: string;
  industry?: Industry;
  phoneNumber?: string;
  questionsAndAnswers?: { question: string; answer: string }[];
  smsOptIn?: boolean;
}

export type MutationFunction<TData, TVariables> = (options?: {
  variables: TVariables;
}) => Promise<FetchResult<TData>>;

export interface SetupWizardExperiments {
  [key: string]: boolean | string;
}

export interface QueryResultData {
  account: {
    name: string;
    phone: string;
    industry: Industry | ""; // "" is for the case when the industry is not set, passing undefined brings up a warning on the mock "Missing field 'industry' while writing result"
    setupWizard: {
      companySize: string;
      heardAboutUs: string;
      questionsAndAnswers?: {
        question: string;
        answer: string;
      }[];
      smsOptIn: boolean;
    };
    accountOwner: { fullName: string; email: { raw: string } };
  };
}

export interface MessageFormat {
  id: string;
  defaultMessage: string;
  description?: string;
}
