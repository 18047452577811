import { Amplitude } from "~/utilities/analytics/Amplitude";
import type { BankAccountInputType, PaymentMethodType } from "../types";

interface EventProperties {
  source: string;
  action: string;
  type?: string;
  tip?: number;
  remember_details?: boolean;
  error_message?: string;
}

export async function trackViewedCheckout(attachedToType: "invoice" | "quote") {
  const properties: EventProperties = {
    source: `Checkout pay ${attachedToType}`,
    action: "Viewed checkout",
  };
  Amplitude.TRACK_EVENT("Interacted with Payment Dialog", properties);
}

export async function trackSelectedPaymentMethodChip(
  attachedToType: "invoice" | "quote",
  paymentMethod: PaymentMethodType,
) {
  const properties: EventProperties = {
    source: `Checkout pay ${attachedToType}`,
    action: `Selected ${paymentMethod} payment method`,
  };
  Amplitude.TRACK_EVENT("Interacted with Payment Dialog", properties);
}

interface PaymentSuccessParams {
  attachedToType: "invoice" | "quote";
  selectedPaymentMethodChip: PaymentMethodType;
  useNewPaymentMethod: boolean;
  selectedBankAccountInputMethod: BankAccountInputType;
  tipAmount: number;
  saveCreditCardDetails: boolean;
  saveBankDetails: boolean;
}

export async function trackPaymentSuccess({
  attachedToType,
  selectedPaymentMethodChip,
  useNewPaymentMethod,
  selectedBankAccountInputMethod,
  tipAmount,
  saveCreditCardDetails,
  saveBankDetails,
}: PaymentSuccessParams) {
  let type = "";
  if (selectedPaymentMethodChip === "creditCard") {
    type = useNewPaymentMethod ? "New credit card" : "Saved credit card";
  } else if (selectedPaymentMethodChip === "bankAccount") {
    if (!useNewPaymentMethod) {
      type = "Saved bank account";
    } else if (selectedBankAccountInputMethod === "login") {
      type = "Plaid bank account";
    } else if (selectedBankAccountInputMethod === "manual") {
      type = "Manual bank account";
    }
  } else if (selectedPaymentMethodChip === "googlePay") {
    type = "Google pay";
  } else if (selectedPaymentMethodChip === "applePay") {
    type = "Apple pay";
  }

  const properties: EventProperties = {
    source: `Checkout pay ${attachedToType}`,
    action: "Payment success",
    type: type,
    tip: tipAmount,
    remember_details: saveCreditCardDetails || saveBankDetails,
  };
  Amplitude.TRACK_EVENT("Interacted with Payment Dialog", properties);
}

export async function trackEditBillingAddress(
  attachedToType: "invoice" | "quote",
) {
  const properties: EventProperties = {
    source: `Checkout pay ${attachedToType}`,
    action: "Clicked Edit Billing Address",
  };
  Amplitude.TRACK_EVENT("Clicked Edit Billing Address", properties);
}

interface trackUseAnotherCardOrUseSavedCardParams {
  attachedToType: "invoice" | "quote";
  useNewPaymentMethod: boolean;
  selectedPaymentMethodChip: PaymentMethodType;
}

export async function trackUseAnotherCardOrUseSavedCard({
  attachedToType,
  useNewPaymentMethod,
  selectedPaymentMethodChip,
}: trackUseAnotherCardOrUseSavedCardParams) {
  const properties: EventProperties = {
    source: `Checkout pay ${attachedToType}`,
    action: useNewPaymentMethod
      ? "Clicked Use Saved Card"
      : "Clicked Use Another Card",
    type: selectedPaymentMethodChip,
  };
  Amplitude.TRACK_EVENT(
    useNewPaymentMethod ? "Clicked Use Saved Card" : "Clicked Use Another Card",
    properties,
  );
}

export async function trackRoutingNumberHelpClick(
  attachedToType: "invoice" | "quote",
) {
  const properties: EventProperties = {
    source: `Checkout pay ${attachedToType}`,
    action: "Clicked Routing Number Help Popover",
  };
  Amplitude.TRACK_EVENT("Clicked Routing Number Help Popover", properties);
}

export async function trackAccountNumberHelpClick(
  attachedToType: "invoice" | "quote",
) {
  const properties: EventProperties = {
    source: `Checkout pay ${attachedToType}`,
    action: "Clicked Account Number Help Popover",
  };
  Amplitude.TRACK_EVENT("Clicked Account Number Help Popover", properties);
}

export async function trackAccountOwnerNameHelpClick(
  attachedToType: "invoice" | "quote",
) {
  const properties: EventProperties = {
    source: `Checkout pay ${attachedToType}`,
    action: "Clicked Account Holder's Name Help Popover",
  };
  Amplitude.TRACK_EVENT(
    "Clicked Account Holder's Name Help Popover",
    properties,
  );
}

interface trackSelectedBankAccountInputChangeParams {
  attachedToType: "invoice" | "quote";
  selectedBankAccountInputMethod: BankAccountInputType;
}

export async function trackSelectedBankAccountInputChange({
  attachedToType,
  selectedBankAccountInputMethod,
}: trackSelectedBankAccountInputChangeParams) {
  const properties: EventProperties = {
    source: `Checkout pay ${attachedToType}`,
    action: "Selected Bank Account Input Change",
    type: selectedBankAccountInputMethod,
  };
  Amplitude.TRACK_EVENT("Selected Bank Account Input Change", properties);
}

interface trackPaymentErrorParams {
  attachedToType: "invoice" | "quote";
  errorMessage: string;
  selectedPaymentMethodChip: PaymentMethodType;
}

export async function trackPaymentError({
  attachedToType,
  errorMessage,
  selectedPaymentMethodChip,
}: trackPaymentErrorParams) {
  const properties: EventProperties = {
    source: `Checkout pay ${attachedToType}`,
    action: "Payment Error",
    type: selectedPaymentMethodChip,
    error_message: errorMessage,
  };
  Amplitude.TRACK_EVENT("Payment Error Occurred", properties);
}
