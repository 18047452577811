import { Button } from "@jobber/components/Button";
import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { Form } from "@jobber/components/Form";
import { InputText } from "@jobber/components/InputText";
import { InputPhoneNumber } from "@jobber/components/InputPhoneNumber";
import { Content } from "@jobber/components/Content";
import { Checkbox } from "@jobber/components/Checkbox";
import { Markdown } from "@jobber/components/Markdown";
import { Routes } from "jobber/setupWizard/types";
import type { SetupWizardStepProps } from "jobber/setupWizard/types";
import { SetupWizardContext } from "jobber/setupWizard/context";
import { generateLastSubmittedStepQandA } from "jobber/setupWizard/utils";
import { messages } from "./messages";
import styles from "../../SetupWizardForms.module.css";

export function ProfilePageForm({
  navigation,
  onMobileWeb,
  signupConsentData,
}: SetupWizardStepProps) {
  const { formatMessage } = useIntl();
  const { wizardData, updateWizardData, saving } =
    useContext(SetupWizardContext);
  const [accountOwnerName, setAccountOwnerName] = useState<string>(
    wizardData?.accountOwnerName ?? "",
  );
  const [phoneNumber, setPhoneNumber] = useState<string>(
    wizardData?.phoneNumber ?? "",
  );

  const [smsOptIn, setSmsOptIn] = useState<boolean>(
    wizardData?.smsOptIn ?? false,
  );

  const handleSubmit = async () => {
    const dataToSend = {
      accountOwnerName,
      phoneNumber,
      smsOptIn,
      questionsAndAnswers: [generateLastSubmittedStepQandA(Routes.profile)],
    };
    updateWizardData(dataToSend, navigation.isFinalStep, navigation.onNext);
  };

  function handleAccountOwnerNameChange(name: string) {
    setAccountOwnerName(name);
  }

  function handlePhoneNumberChange(phone: string) {
    setPhoneNumber(phone);
    !phone && setSmsOptIn(false);
  }

  function handleSmsOptInChange() {
    setSmsOptIn(!smsOptIn);
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Content>
        <InputText
          name="accountOwnerName"
          placeholder={formatMessage(messages.fullNamePlaceholder)}
          value={accountOwnerName}
          validations={{
            required: {
              message: formatMessage(messages.fullNameError),
              value: true,
            },
          }}
          onChange={handleAccountOwnerNameChange}
          size="large"
        />
        <InputPhoneNumber
          name="phoneNumber"
          placeholder={formatMessage(messages.phoneNumberPlaceholder)}
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          required={false}
          size="large"
        />
        {phoneNumber && !signupConsentData.hasData && (
          <Checkbox checked={smsOptIn} onChange={handleSmsOptInChange}>
            <div data-testid="sms-opt-in">
              <Markdown
                content={formatMessage(messages.smsOptIn)}
                externalLink={true}
              />
            </div>
          </Checkbox>
        )}
        {phoneNumber &&
          signupConsentData.consentRegion &&
          signupConsentData.consentRegion.toLowerCase() === "us" && (
            <div data-testid="sms-opt-in-us-verbiage">
              <Markdown
                content={formatMessage(messages.smsOptInUSVerbiage)}
                externalLink={true}
              />
            </div>
          )}
        <div className={styles.button}>
          <Button
            id={"profilePageSubmit"}
            fullWidth={onMobileWeb}
            label={navigation.nextCtaCopy}
            submit={true}
            loading={saving}
            size="large"
          />
        </div>
      </Content>
    </Form>
  );
}
