import { defineMessages } from "react-intl";

export const messages = defineMessages({
  defaultSelectPlaceholder: {
    id: "setupWizard.formControl.defaultSelectPlaceholder",
    defaultMessage: "Please select",
    description: "Default select for the setup wizard",
  },
  jobberLogoAltText: {
    id: "setupWizard.setupWizardRoute.jobberLogoAltText",
    defaultMessage: "Jobber Logo",
    description: "Jobber logo alt text for the setup wizard",
  },
  nextButtonLabel: {
    id: "setupWizard.setupWizardRoute.nextButtonLabel",
    defaultMessage: "Next",
    description: "Next button for the setup wizard",
  },
  finishButtonLabel: {
    id: "setupWizard.setupWizardRoute.finishButtonLabel",
    defaultMessage: "Get Started",
    description: "Finish button for the setup wizard",
  },
  serverErrorCopy: {
    id: "setupWizard.formColumn.serverErrorCopy",
    defaultMessage: "Something went wrong. Please try again in a few minutes.",
    description: "Server error copy for the setup wizard",
  },
  paperRipAltText: {
    id: "setupWizard.mediaColumn.paperRippedAltText",
    defaultMessage: "paper rip",
    description: "Paper rip alt text for the setup wizard",
  },
  maskingTapeBgAltText: {
    id: "setupWizard.maskingTapeFact.maskingTapeBgAltText",
    defaultMessage: "Masking Tape Background",
    description: "Masking tape background alt text for the setup wizard",
  },
  didYouKnowAltText: {
    id: "setupWizard.maskingTapeFact.didYouKnowAltText",
    defaultMessage: "Did you know?",
    description: "Did you know alt text for the setup wizard",
  },
  maskingTapeAltText: {
    id: "setupWizard.maskingTapeStats.maskingTapeAltText",
    defaultMessage: "Masking Tape",
    description: "Masking tape alt text for the setup wizard",
  },
  jobberOnLaptopMobileDeviceAltText: {
    id: "setupWizard.promotionPage.jobberOnLaptopMobileDeviceAltText",
    defaultMessage: "Jobber on laptop and mobile device",
    description:
      "Jobber on laptop and mobile device alt text for the setup wizard",
  },
  promotionPageCancelButtonText: {
    id: "setupWizard.promotionPage.promotionPageCancelButtonText",
    defaultMessage: "No Thanks",
    description: "Promotion page cancel button text for the setup wizard",
  },
  backgroundImageAltText: {
    id: "setupWizard.SPQuote.backgroundImageAltText",
    defaultMessage: "Background",
    description: "Background image alt text for the setup wizard",
  },
});
