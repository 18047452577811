import { Button } from "@jobber/components/Button";
import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { Form } from "@jobber/components/Form";
import { InputText } from "@jobber/components/InputText";
import { Text } from "@jobber/components/Text";
import { Option, Select } from "@jobber/components/Select";
import { Content } from "@jobber/components/Content";
import { Emphasis } from "@jobber/components/Emphasis";
import { Industry } from "~/utilities/API/graphql";
import type { SetupWizardStepProps } from "jobber/setupWizard/types";
import { SetupWizardContext } from "jobber/setupWizard/context";
import { messages as setupWizardMessages } from "jobber/setupWizard/messages";
import { messages } from "./messages";
import styles from "../../SetupWizardForms.module.css";

// eslint-disable-next-line max-statements
export function CommunityPageForm({
  navigation,
  industryMap,
  onMobileWeb,
}: SetupWizardStepProps) {
  const { formatMessage } = useIntl();
  const { wizardData, updateWizardData, saving } =
    useContext(SetupWizardContext);
  const [accountOwnerName, setAccountOwnerName] = useState<string>(
    wizardData?.accountOwnerName ?? "",
  );

  const [companyName, setCompanyName] = useState<string>(
    wizardData?.companyName ?? "",
  );

  const [industry, setIndustry] = useState<Industry | undefined>(
    wizardData?.industry,
  );

  const handleSubmit = async () => {
    const dataToSend = {
      accountOwnerName,
      companyName: companyName || `${accountOwnerName}'s Company`,
      industry,
    };
    updateWizardData(dataToSend, navigation.isFinalStep, navigation.onNext);
  };

  function handleAccountOwnerNameChange(name: string) {
    setAccountOwnerName(name);
  }

  function handleCompanyNameChange(name: string) {
    setCompanyName(name);
  }

  function handleIndustryChange(selectedIndustry: Industry) {
    setIndustry(Industry[selectedIndustry]);
    updateWizardData({ industry: selectedIndustry }, false, undefined, true);
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Content>
        <InputText
          name="accountOwnerName"
          placeholder={formatMessage(messages.fullNamePlaceholder)}
          value={accountOwnerName}
          validations={{
            required: {
              message: formatMessage(messages.fullNameError),
              value: true,
            },
          }}
          onChange={handleAccountOwnerNameChange}
          size="large"
        />
        <InputText
          name="companyName"
          placeholder={formatMessage(messages.companyNamePlaceholder)}
          value={companyName}
          onChange={handleCompanyNameChange}
          size="large"
        />
        <Select
          name="industry"
          placeholder={formatMessage(messages.industryPlaceholder)}
          value={industry}
          onChange={handleIndustryChange}
          validations={{
            required: {
              message: formatMessage(messages.industryError),
              value: true,
            },
          }}
          size="large"
        >
          <Option key={-1} value="" disabled>
            {formatMessage(setupWizardMessages.defaultSelectPlaceholder)}
          </Option>
          {industryMap.map((currCategory, idx1) => (
            <optgroup key={idx1} label={currCategory.category}>
              {currCategory.industries.map((currIndustry, idx2) => (
                <Option
                  key={`${idx1}-${idx2}`}
                  value={currIndustry.industryEnum}
                >
                  {currIndustry.displayName}
                </Option>
              ))}
            </optgroup>
          ))}
        </Select>
        <Text>
          <Emphasis variation="bold">
            {formatMessage(messages.communityLoginPrimeMessage)}
          </Emphasis>
        </Text>
        <div className={styles.button}>
          <Button
            id={"pageSubmit"}
            fullWidth={onMobileWeb}
            label={messages.communitySubmissionButton.defaultMessage}
            submit={true}
            loading={saving}
            size="large"
          />
        </div>
      </Content>
    </Form>
  );
}
