import { useMutation } from "@apollo/client";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Emphasis } from "@jobber/components/Emphasis";
import { Icon } from "@jobber/components/Icon";
import { Modal } from "@jobber/components/Modal";
import { Text } from "@jobber/components/Text";
import React, {
  type MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import {
  GROW_FEATURE_GUIDE_COMPLETED,
  type GrowFeatureGuideCompletedData,
} from "jobber/connectToGrowTrial/components/ConnectToGrowFiveDayUsageReminderModal/GrowFeatureGuideCompleted.graphql";
import {
  CallToAction,
  type CallToActionRef,
  convertCTA,
  dismissCTA,
} from "~/jobber/settings/users/components/CallToAction/CallToAction";
import { IntlProvider } from "@translations/IntlProvider";
import { APIProvider } from "~/utilities/API/APIProvider";
import { messages } from "./messages";
import styles from "./ConnectToGrowFiveDayUsageReminderModal.module.css";

export interface ConnectToGrowFiveDayUsageReminderModalProps {
  shouldSeeSMSWarning: boolean;
  completedFeatureGuide: boolean;
  upgradeablePlanNameIdentifier?: string;
}

function ConnectToGrowFiveDayUsageReminderModal({
  shouldSeeSMSWarning,
  completedFeatureGuide,
}: ConnectToGrowFiveDayUsageReminderModalProps) {
  const { formatMessage } = useIntl();
  const ctaName = "connect_to_grow_trial_five_day_usage_reminder_modal";
  const ctaRef = useRef() as MutableRefObject<CallToActionRef>;
  const features: (keyof typeof messages)[] = [
    "fiveDayUsageReminderModalFeatureOne",
    "fiveDayUsageReminderModalFeatureTwo",
    "fiveDayUsageReminderModalFeatureThree",
    "fiveDayUsageReminderModalFeatureFour",
  ];
  const [showModal, setShowModal] = useState(true);

  return (
    <CallToAction ref={ctaRef} ctaName={ctaName}>
      <Modal
        title={formatMessage(messages.fiveDayUsageReminderModalTitle)}
        open={showModal}
        onRequestClose={handleModalDismissal}
        dismissible
      >
        <div className={styles.fiveDayUsageReminderModalContainer}>
          <Content spacing={"large"}>
            {completedFeatureGuide ? (
              <Text>
                {formatMessage(
                  messages.fiveDayUsageReminderModalCompletedGuideCopyParagraphOne,
                )}
              </Text>
            ) : (
              <Text>
                {formatMessage(
                  messages.fiveDayUsageReminderModalNotCompletedGuideCopyParagraphOne,
                )}
              </Text>
            )}

            <Text>
              <Emphasis variation="bold">
                {formatMessage(
                  messages.fiveDayUsageReminderModalFeatureListHeader,
                )}
              </Emphasis>
            </Text>

            <div
              className={styles.fiveDayUsageReminderModalFeatureGridContainer}
            >
              {features.map(feature => (
                <div key={feature}>
                  <div>
                    <div
                      className={styles.fiveDayUsageReminderModalFeatureIcon}
                    >
                      <Icon name="checkmark" color="green" />
                    </div>
                    {formatMessage(messages[feature])}
                  </div>
                </div>
              ))}
            </div>

            {shouldSeeSMSWarning && (
              <div
                className={styles.fiveDayUsageReminderModalSMSWarningContainer}
              >
                <div className={styles.fiveDayUsageReminderModalSMSWarningIcon}>
                  <Icon name="alert" color="greyBlue" size={"base"} />
                </div>
                <div className={styles.fiveDayUsageReminderModalSMSWarningCopy}>
                  <Text>
                    {formatMessage(
                      messages.fiveDayUsageReminderModalSMSWarning,
                    )}
                  </Text>
                </div>
              </div>
            )}

            <div className={styles.fiveDayUsageReminderModalCopyParagraphTwo}>
              {completedFeatureGuide ? (
                <Text>
                  {formatMessage(
                    messages.fiveDayUsageReminderModalCompletedGuideCopyParagraphTwo,
                  )}
                </Text>
              ) : (
                <Text>
                  {formatMessage(
                    messages.fiveDayUsageReminderModalNotCompletedGuideCopyParagraphTwo,
                  )}
                </Text>
              )}
            </div>
          </Content>
        </div>
        <div className={styles.fiveDayUsageReminderButtonsContainer}>
          {completedFeatureGuide ? (
            <div className={styles.fiveDayUsageReminderButtonSet}>
              <Button
                label={formatMessage(
                  messages.fiveDayUsageReminderModalStayOnTheGrowPlanCTA,
                )}
                onClick={handleStayOnGrowClick}
                type="secondary"
              />
              <Button
                label={formatMessage(
                  messages.fiveDayUsageReminderModalBookACallCTA,
                )}
                onClick={handleBookACallClick}
                url={formatMessage(
                  messages.fiveDayUsageReminderModalBookACallUrl,
                )}
              />
            </div>
          ) : (
            <div className={styles.fiveDayUsageReminderButtonSet}>
              <div className={styles.fiveDayUsageReminderButtonSetFirstItem}>
                <Button
                  label={formatMessage(
                    messages.fiveDayUsageReminderModalBookACallCTA,
                  )}
                  onClick={handleBookACallClick}
                  url={formatMessage(
                    messages.fiveDayUsageReminderModalBookACallUrl,
                  )}
                  type="secondary"
                />
              </div>
              <Button
                label={formatMessage(
                  messages.fiveDayUsageReminderModalTryGrowFeaturesCTA,
                )}
                onClick={handleTryGrowFeaturesClick}
                url={formatMessage(
                  messages.fiveDayUsageReminderModalTryGrowFeaturesUrl,
                )}
              />
            </div>
          )}
        </div>
      </Modal>
    </CallToAction>
  );

  function handleModalDismissal() {
    dismissCTA(ctaRef)();
    setShowModal(false);
  }

  function handleBookACallClick() {
    convertCTA(ctaRef, { type: "book_a_call" })();
    setShowModal(false);
  }

  function handleTryGrowFeaturesClick() {
    convertCTA(ctaRef, { type: "try_grow_features" })();
    setShowModal(false);
  }

  async function handleStayOnGrowClick() {
    convertCTA(ctaRef, { type: "stay_on_grow" })();
    setShowModal(false);
    window.location.href = "/accounts/billing_info/pricing";
  }
}

export function WrappedConnectToGrowFiveDayUsageReminderModal(): JSX.Element {
  const [growFeatureGuideCompletedData, { data, loading }] =
    useMutation<GrowFeatureGuideCompletedData>(GROW_FEATURE_GUIDE_COMPLETED);

  const fetchGrowFeatureGuideCompletedData = useCallback(async () => {
    return growFeatureGuideCompletedData();
  }, [growFeatureGuideCompletedData]);

  useEffect(() => {
    void fetchGrowFeatureGuideCompletedData();
  }, [fetchGrowFeatureGuideCompletedData]);

  const growFeatureGuideCompleted =
    data?.setupGuideStatus?.setupGuide?.completed ?? false;

  const growFeatureGuideSteps =
    data?.setupGuideStatus?.setupGuide?.steps?.nodes || [];

  const growFeatureGuideIncludesSMSStep = growFeatureGuideSteps.some(
    step => step.step === "sms_setup",
  );

  return (
    <IntlProvider>
      <APIProvider>
        {!loading && data && (
          <ConnectToGrowFiveDayUsageReminderModal
            shouldSeeSMSWarning={growFeatureGuideIncludesSMSStep}
            completedFeatureGuide={growFeatureGuideCompleted}
          />
        )}
      </APIProvider>
    </IntlProvider>
  );
}

export { WrappedConnectToGrowFiveDayUsageReminderModal as ConnectToGrowFiveDayUsageReminderModal };
