import { useMutation, useSubscription } from "@apollo/client";
import type { OnDataOptions } from "@apollo/client";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuidV4 } from "uuid";
import { Spinner } from "@jobber/components/Spinner";
import { Banner } from "@jobber/components/Banner";
import { parseISO } from "date-fns";
import { Interaction } from "~/utilities/API/graphql";
import type {
  AiAssistantAction,
  AiAssistantFollowup,
  AiAssistantLoadMutation,
  AiAssistantLoadMutationVariables,
  AiAssistantMessage,
  AiAssistantPromptInput,
  AiAssistantPromptResponse,
  AiAssistantQuestionInterpretation,
  AiAssistantSendPromptMutation,
  AiAssistantSendPromptMutationVariables,
  AiAssistantSubscriptionSubscription,
  AiAssistantSubscriptionSubscriptionVariables,
  AiAssistantVisual,
} from "~/utilities/API/graphql";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { JobberAssistant } from "./JobberAssistant";
import type { InteractionItem } from "./JobberAssistant";
import { messageType } from "./ConversationListItem";
import styles from "./JobberAssistantLoader.module.css";
import {
  AI_ASSISTANT_LOAD,
  AI_ASSISTANT_SEND_PROMPT,
  AI_ASSISTANT_SUBSCRIPTION,
} from "./JobberAssistant.graphql";
import { handleBackgroundActions } from "./handleBackgroundActions";
import { AssistantContext } from "../context";
import { useAssistantPromptEventQueue } from "../AssistantPromptEventQueueProvider";
import type { PromptType } from "../AssistantPromptEventQueueProvider";

export interface JobberAssistantLoaderProps {
  conversationIdToResume?: string;
  messages?: Array<AiAssistantMessage>;
  closeChatSidebar: () => void;
  showInitialMessage: boolean;
}

// eslint-disable-next-line max-statements
export function JobberAssistantLoader({
  conversationIdToResume,
  showInitialMessage,
  messages,
  closeChatSidebar,
}: JobberAssistantLoaderProps) {
  const { dequeuePrompt, isEmpty: promptQueueIsEmpty } =
    useAssistantPromptEventQueue();
  const isSubscriptionActive = useRef(false);
  const [isInitializingSubscription, setIsInitializingSubscription] =
    useState(true);
  const [conversationId] = useState(conversationIdToResume ?? uuidV4());
  const [errorMessage, setErrorMessage] = useState<string>();
  const [conversationList, setConversationList] = useState<InteractionItem[]>(
    buildInteractionItems(messages),
  );

  const { error: subscriptionError } = useSubscription<
    AiAssistantSubscriptionSubscription,
    AiAssistantSubscriptionSubscriptionVariables
  >(AI_ASSISTANT_SUBSCRIPTION, {
    variables: {
      conversationId,
    },
    onData: handleSubscriptionData,
  });

  const [loadAssistant, { loading: assistantMutationLoading }] = useMutation<
    AiAssistantLoadMutation,
    AiAssistantLoadMutationVariables
  >(AI_ASSISTANT_LOAD);

  const [sendPromptAssistant, { loading: sendPromptLoading }] = useMutation<
    AiAssistantSendPromptMutation,
    AiAssistantSendPromptMutationVariables
  >(AI_ASSISTANT_SEND_PROMPT);

  const sendPrompt = useCallback(
    (prompt: PromptType) => {
      if (prompt.message) {
        const input = buildPromptInput(prompt);
        Amplitude.TRACK_EVENT("Interacted with Jobber Assistant", {
          name: "request_sent",
          source: input.origin?.interaction,
        });
        sendPromptAssistant({
          variables: {
            conversationId,
            requestId: uuidV4(),
            input,
          },
        }).catch((err: Error) => {
          setErrorMessage(err.message);
        });
      }
    },
    [conversationId, sendPromptAssistant, setErrorMessage],
  );

  useEffect(() => {
    if (
      !promptQueueIsEmpty &&
      !isInitializingSubscription &&
      !assistantMutationLoading
    ) {
      const messageToProcess = dequeuePrompt();
      messageToProcess && sendPrompt(messageToProcess);
    }
  }, [
    promptQueueIsEmpty,
    dequeuePrompt,
    sendPrompt,
    isInitializingSubscription,
    assistantMutationLoading,
  ]);

  useEffect(() => {
    if (subscriptionError) {
      setIsInitializingSubscription(false);
    }
  }, [subscriptionError]);

  const conversationListWithLoadingState: InteractionItem[] = useMemo(() => {
    const lastMessageType = conversationList[conversationList.length - 1]?.type;
    const lastMessageIsRequest = lastMessageType === messageType.REQUEST;
    const shouldDisplayLoadingIcon = sendPromptLoading || lastMessageIsRequest;

    if (shouldDisplayLoadingIcon) {
      return [
        ...conversationList,
        buildLoadingConversationListItem(conversationList),
      ];
    }

    return conversationList;
  }, [conversationList, sendPromptLoading]);

  return (
    <>
      {isInitializingSubscription ? (
        <div className={styles.loadingWrapper} data-testid="loading-spinner">
          <Spinner size="base" />
        </div>
      ) : errorMessage || subscriptionError ? (
        <Banner type="error" dismissible={false}>
          {errorMessage}
          {subscriptionError?.message}
        </Banner>
      ) : (
        <AssistantContext.Provider value={{ closeDrawer: closeChatSidebar }}>
          <JobberAssistant
            conversationList={conversationListWithLoadingState}
            onPrompt={sendPrompt}
            showInitialMessage={showInitialMessage}
          />
        </AssistantContext.Provider>
      )}
    </>
  );

  async function handleSubscriptionData(
    response: OnDataOptions<AiAssistantSubscriptionSubscription>,
  ) {
    const { data, error } = response.data;

    if (error) {
      setErrorMessage(error.message);
      return;
    }

    if (data) {
      return processSubscriptionData(data);
    }
  }

  async function processSubscriptionData(
    data: AiAssistantSubscriptionSubscription,
  ) {
    // The first time we get subscription data, we've just connected the subscription.
    if (!isSubscriptionActive.current) {
      isSubscriptionActive.current = true;
      if (!conversationList || conversationList.length === 0) {
        sendMutationToLoadData();
      }
      setIsInitializingSubscription(false);
    }

    // Once the load response arrives, stop showing the loading state.
    if (
      data.aiAssistantMessages.content?.__typename ==
        "AiAssistantLoadResponse" &&
      data.aiAssistantMessages.content?.errors.length <= 0
    ) {
      setIsInitializingSubscription(false);
    }

    if (data?.aiAssistantMessages) {
      const item = buildInteractionItem(data.aiAssistantMessages);
      if (item) {
        handleNewConversationItem(item);
      }

      handleBackgroundActions(
        data.aiAssistantMessages.requestId,
        data.aiAssistantMessages.content,
      );
    }
  }

  function handleNewConversationItem(newInteraction: InteractionItem) {
    const nextConversationList = [...conversationList];
    const lastInteraction = conversationList[conversationList.length - 1];

    if (
      lastInteraction?.type === messageType.PARTIAL_RESPONSE &&
      newInteraction.type === messageType.PARTIAL_RESPONSE &&
      newInteraction.message.length < lastInteraction?.message.length
    ) {
      // Ignore the new interaction: it's shorter.
      return;
    }

    if (
      (lastInteraction?.type === messageType.RESPONSE ||
        lastInteraction?.type === messageType.ERROR) &&
      newInteraction.type === messageType.PARTIAL_RESPONSE &&
      lastInteraction.requestId === newInteraction.requestId
    ) {
      // Ignore the new interaction: it's a partial response after a complete response.
      return;
    }

    if (lastInteraction?.type === messageType.PARTIAL_RESPONSE) {
      // Remove the partial response: it'll be replaced by the new interaction.
      nextConversationList.pop();
    }

    orderConversationList(
      lastInteraction,
      newInteraction,
      nextConversationList,
    );
    setConversationList(nextConversationList);
  }

  function orderConversationList(
    lastInteraction: InteractionItem | undefined,
    newInteraction: InteractionItem,
    nextConversationList: InteractionItem[],
  ) {
    if (
      lastInteraction?.createdAt &&
      parseISO(newInteraction.createdAt) < parseISO(lastInteraction.createdAt)
    ) {
      // The interaction needs to be inserted at the top.
      nextConversationList.unshift(newInteraction);
    } else {
      nextConversationList.push(newInteraction);
    }

    if (newInteraction.type === messageType.RESPONSE) {
      Amplitude.TRACK_EVENT("Interacted with Jobber Assistant", {
        name: "response_seen",
      });
    }
  }

  function buildInteractionItems(
    items: AiAssistantMessage[] = [],
  ): InteractionItem[] {
    return items
      .map(buildInteractionItem)
      .filter(item => !!item) as InteractionItem[];
  }

  /* eslint-disable max-statements */
  function buildInteractionItem(
    item: AiAssistantMessage,
  ): InteractionItem | undefined {
    let message = "";
    let type: messageType | undefined;
    let footnotes: string[] | undefined;
    let actions: AiAssistantAction[] | undefined;
    let visuals: AiAssistantVisual[] | undefined;
    let followups: AiAssistantFollowup[] = [];
    let questionInterpretation: AiAssistantQuestionInterpretation | undefined;

    const { content, createdAt, requestId } = item;

    switch (content?.__typename) {
      case "AiAssistantPromptRequest":
        if (!content.prompt) {
          return;
        }

        type = messageType.REQUEST;
        message = content.prompt;
        break;
      case "AiAssistantPartialResponse":
        if (!content.response) {
          return;
        }

        type = messageType.PARTIAL_RESPONSE;
        message = content.response;
        break;
      case "AiAssistantPromptResponse":
        if (content?.errors.length > 0) {
          type = messageType.ERROR;
          message = content.errors.join(" ");
        } else if (content.response) {
          type = messageType.RESPONSE;
          message = content.response;
          followups = content.followups;
          questionInterpretation = parseInterpretationFromContent(content);
        } else {
          return;
        }

        ({ footnotes, actions, visuals } = content);
        break;
      default:
        return;
    }

    return {
      id: uuidV4(),
      requestId,
      createdAt,
      message,
      footnotes,
      type,
      actions,
      visuals: visuals ?? [],
      followups,
      questionInterpretation,
    };
  }
  /* eslint-enable max-statements */

  function sendMutationToLoadData() {
    loadAssistant({
      variables: {
        conversationId,
        input: {
          includeAccount: true,
        },
        requestId: uuidV4(),
      },
    }).catch((err: Error) => {
      setErrorMessage(err.message);
    });
  }

  function buildPromptInput(prompt: PromptType): AiAssistantPromptInput {
    const input: AiAssistantPromptInput = {
      prompt: prompt.message,
    };
    if (prompt.widgetName) {
      input.origin = {
        interaction: Interaction.INSIGHT_WIDGET,
        type: prompt.widgetName,
      };
    } else {
      input.origin = {
        interaction: Interaction.CHATBOT,
      };
    }
    return input;
  }
}

export function buildLoadingConversationListItem(
  list: InteractionItem[],
): InteractionItem {
  const lastItem = list[list.length - 1];
  const requestId =
    lastItem && lastItem.type === messageType.REQUEST
      ? lastItem.requestId
      : "loading";

  return {
    followups: [],
    id: "loading",
    requestId,
    message: "loading",
    createdAt: new Date().toISOString(),
    questionInterpretation: undefined,
    type: messageType.LOADING,
    visuals: [],
    actions: [],
  };
}

function parseInterpretationFromContent(
  content: AiAssistantPromptResponse,
): AiAssistantQuestionInterpretation | undefined {
  const { explanation } = content;
  if (!explanation) {
    return;
  }

  const hasQuestionInterpretation =
    explanation.__typename === "AiAssistantQuestionInterpretation";
  if (hasQuestionInterpretation) {
    return explanation;
  }
}
