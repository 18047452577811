import React, { useEffect, useState } from "react";
import { Page } from "@jobber/components/Page";
import { useIntl } from "react-intl";
import { useClientSegmentPageNavigation } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentPageNavigation";
import { interactedWithClientSegmentAmplitudeEvent } from "jobber/campaigns/amplitude/events";
import {
  CampaignsBreadCrumb,
  breadCrumbMessages,
} from "jobber/campaigns/components/Breadcrumbs";
import { BottomBar } from "jobber/campaigns/components/Bottombar/BottomBar";
import { IntlProvider } from "@translations/IntlProvider";
import { APIProvider } from "~/utilities/API/APIProvider";
import { useCommsCampaignQuery } from "jobber/campaigns/hooks/useCommsCampaignQuery";
import { type ClientSegmentFragment, Template } from "~/utilities/API/graphql";
import { usePreventEditCampaign } from "jobber/campaigns/hooks/usePreventEditCampaign/usePreventEditCampaign";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import { useCampaignUpsellSplit } from "jobber/campaigns/hooks/useCampaignUpsellSplit";
import { ClientSegmentInternals } from "jobber/campaigns/views/SelectClientSegmentPage/components/ClientSegmentInternals";
import { useSaveOnDirty } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useSaveOnDirty";
import { getRecommendedClientSegment } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentOptions";
import { useCampaignAutomationsSplit } from "jobber/campaigns/hooks/useCampaignAutomationsSplit/useCampaignAutomationsSplit";
import { SetAutomationRules } from "jobber/campaigns/views/SelectClientSegmentPage/components/SetAutomationRules/SetAutomationRules";
import { useAutomationControls } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useAutomationControls/useAutomationControls";
import { getAutomationRule } from "jobber/campaigns/utils/automationRuleUtils";
import { isAutomatedCampaignType, isEnumValue } from "jobber/campaigns/utils";
import { messages } from "./messages";
import styles from "./SelectClientSegmentPage.module.css";

export interface SelectClientSegmentPageProps {
  campaignId?: string;
  templateType?: string;
}

export function SelectClientSegmentPage(props: SelectClientSegmentPageProps) {
  return (
    <APIProvider>
      <IntlProvider>
        <SelectClientSegmentPageInternal {...props} />
      </IntlProvider>
    </APIProvider>
  );
}

// eslint-disable-next-line max-statements
function SelectClientSegmentPageInternal({
  campaignId,
  templateType,
}: SelectClientSegmentPageProps): JSX.Element {
  const { formatMessage } = useIntl();
  const [clientSegment, setClientSegment] = useState<
    ClientSegmentFragment | undefined
  >();
  const { isInCampaignAutomations } = useCampaignAutomationsSplit();

  const { onCancel, onCancelLabel, onCampaignNotFound, onNext } =
    useClientSegmentPageNavigation(campaignId);
  const { getPageStep } = useCampaignUpsellSplit();
  const {
    campaignContent: { setTemplateType },
    campaignSegment: { setNotDirty, setSelectedSegmentType },
  } = useCampaignWizardContext();

  const { data, loading } = useCommsCampaignQuery({
    campaignId: campaignId,
    onCampaignNotFound,
  });

  const { saveOnNext, updateSegmentCriteriaLoading } = useSaveOnDirty({
    initialClientSegment: clientSegment,
    onNextNavigation: onNext,
  });

  const initialClientSegment = data?.commsCampaign?.clientSegment;

  let campaignTemplateType = templateType;
  if (
    data?.commsCampaign?.templates &&
    data.commsCampaign.templates.nodes.length > 0
  ) {
    campaignTemplateType = data.commsCampaign.templates.nodes[0].type;
  }

  if (
    campaignTemplateType !== undefined &&
    !isEnumValue(Template, campaignTemplateType)
  ) {
    onCampaignNotFound();
  }

  const defaultAutomationRule = getAutomationRule(
    campaignTemplateType as Template,
  );

  useEffect(() => {
    if (campaignTemplateType) {
      setTemplateType(campaignTemplateType as Template);
    }
  }, [campaignTemplateType, setTemplateType]);

  useEffect(() => {
    if (!clientSegment && initialClientSegment) {
      setClientSegment(initialClientSegment);
      setSelectedSegmentType(initialClientSegment?.type);
      setNotDirty();
    } else if (!loading && !initialClientSegment && campaignTemplateType) {
      const recommendedDefault = getRecommendedClientSegment(
        campaignTemplateType as Template,
      );
      setSelectedSegmentType(recommendedDefault, true);
    }
  }, [
    initialClientSegment,
    clientSegment,
    setNotDirty,
    setSelectedSegmentType,
    campaignTemplateType,
    loading,
  ]);

  useEffect(() => {
    interactedWithClientSegmentAmplitudeEvent({
      interaction: "List Viewed",
    });
  }, []);

  usePreventEditCampaign({ campaignStatus: data?.commsCampaign?.status });

  const enableAutomation =
    isInCampaignAutomations &&
    isAutomatedCampaignType(
      campaignTemplateType as Template,
      data?.commsCampaign?.isAutomated,
    );

  const pageTitle = enableAutomation
    ? formatMessage(messages.automationsTitle)
    : formatMessage(messages.clientSegmentPageTitle);

  const {
    conditions: automationConditions,
    dispatch: automationDispatch,
    trigger: triggerArguments,
    setTrigger: setTriggerArguments,
    onNext: onAutomationNext,
    loading: automationOnNextIsLoading,
    errors: automationRuleErrors,
    clearError,
  } = useAutomationControls(
    onNext,
    data?.commsCampaign?.automationRule,
    campaignTemplateType as Template,
    isAutomatedCampaignType(
      campaignTemplateType as Template,
      data?.commsCampaign?.isAutomated,
    ),
    defaultAutomationRule,
  );

  const onNextButton = () => {
    if (!enableAutomation) {
      return saveOnNext();
    }
    return onAutomationNext();
  };

  return (
    <div className={styles.container}>
      <CampaignsBreadCrumb
        currentStep={formatMessage(breadCrumbMessages.recipientsLabel)}
        onBack={onCancel}
      />
      <div className={styles.segmentsPageContainer}>
        <Page title={pageTitle} width="fill">
          {enableAutomation && defaultAutomationRule ? (
            <SetAutomationRules
              automationRule={defaultAutomationRule}
              conditions={automationConditions}
              dispatch={automationDispatch}
              triggerArguments={triggerArguments}
              setTriggerArguments={setTriggerArguments}
              errors={automationRuleErrors}
              clearError={clearError}
            />
          ) : (
            <ClientSegmentInternals
              initialClientSegment={clientSegment}
              setClientSegment={setClientSegment}
              loading={loading}
            />
          )}
        </Page>
      </div>
      <BottomBar
        loading={updateSegmentCriteriaLoading || automationOnNextIsLoading}
        onNext={onNextButton}
        onCancel={onCancel}
        onCancelLabel={onCancelLabel}
        step={getPageStep("segment")}
      />
    </div>
  );
}
