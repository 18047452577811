import React, { useContext, useMemo } from "react";
import { useIntl } from "react-intl";
import {
  Banner,
  Content,
  Flex,
  Heading,
  Option,
  Select,
  Switch,
} from "@jobber/components";
import { Button } from "@jobber/components/Button";
import { showToast } from "@jobber/components/Toast";
import {
  ClientNotificationId,
  type MessageTemplateEmail,
  type MessageTemplateSms,
  NotificationDeliveryMethod,
} from "~/utilities/API/graphql";
import { useFollowUpMessageSettingsQuery } from "jobber/reviews/views/ReviewsPage/hooks/useFollowUpMessageSettingsQuery";
import { useUpdateNotification } from "jobber/reviews/views/ReviewsPage/hooks/useUpdateNotification";
import { LoadingLayout } from "jobber/reviews/views/ReviewsPage/components/ReviewsSettings/LoadingLayout/LoadingLayout";
import {
  DrawerView,
  ReviewsSettingsDrawerContext,
} from "jobber/reviews/views/ReviewsPage/context/ReviewsSettingsDrawerContext";
import { MessagePreviewer } from "jobber/reviews/views/ReviewsPage/components/ReviewsSettings/ReviewMessagePreviewer/ReviewMessagePreviewer";
import {
  Controller,
  FormProvider,
  type FormSubmitHandler,
  type SubmitHandler,
  useFormWithDefaults,
} from "~/utilities/reactHookForm/module";
import { messages } from "../messages";
import styles from "../ReviewsSettings.module.css";
import type { Schedules } from "../types";

interface FollowUpMessageSettingsProps {
  scheduleValue: Schedules;
  hasDPN: boolean;
  showSwitch?: boolean;
}

interface FormData {
  deliveryMethod: NotificationDeliveryMethod;
  template: MessageTemplateEmail | MessageTemplateSms;
  scheduleEnabled: boolean;
}

export function FollowUpMessageSettings({
  scheduleValue,
  hasDPN,
  showSwitch = false,
}: FollowUpMessageSettingsProps) {
  const { formatMessage } = useIntl();
  const { drawerActions } = useContext(ReviewsSettingsDrawerContext);

  const {
    loading,
    error: fetchMethodError,
    schedules,
    templates,
  } = useFollowUpMessageSettingsQuery();

  const { scheduleId, scheduleDeliveryMethod, scheduleEnabled } =
    useMemo(() => {
      const schedule = schedules.find(
        node => node?.offset?.value === scheduleValue,
      );
      return {
        scheduleId: schedule?.id,
        scheduleDeliveryMethod: schedule?.deliveryMethod,
        // TODO: Once we release we can remove this check and rely on schedule value
        scheduleEnabled: showSwitch ? !!schedule?.enabled : true,
      };
    }, [schedules, showSwitch, scheduleValue]);

  const { updateNotification } = useUpdateNotification();

  if (loading) {
    return <LoadingLayout />;
  }

  const defaultValues = {
    deliveryMethod: scheduleDeliveryMethod || NotificationDeliveryMethod.EMAIL,
    template:
      scheduleDeliveryMethod === "EMAIL" ? templates?.email : templates?.sms,
    scheduleEnabled,
  };

  return (
    <>
      <div className={styles.backButton}>
        <Button
          type="tertiary"
          variation="subtle"
          icon="longArrowLeft"
          ariaLabel={formatMessage(messages.messageSettingsBackAriaLabel)}
          onClick={() => {
            drawerActions?.goTo(DrawerView.ManageSettings);
          }}
        />
      </div>

      {fetchMethodError && (
        <Banner type={"error"} dismissible={true}>
          {formatMessage(messages.deliveryMethodError)}
        </Banner>
      )}

      <div className={styles.settingsContainer}>
        <SettingsForm
          defaultValues={defaultValues}
          hasDPN={hasDPN}
          showSwitch={showSwitch}
          templates={templates}
          onSubmit={async (
            { deliveryMethod, scheduleEnabled: enabled },
            { setError },
          ) => {
            const errors = await updateNotification({
              clientNotificationId:
                ClientNotificationId.REVIEW_REQUEST_REMINDER,
              deliveryMethod,
              scheduleId,
              scheduleEnabled: enabled,
            });

            if (errors.length > 0) {
              setError("root.serverError", {
                type: "serverError",
                message: formatMessage(messages.generalError),
              });
            } else {
              drawerActions?.closeSideDrawer();
              showToast({
                message: formatMessage(messages.messageSettingsSaveSuccess),
              });
            }
          }}
        />
      </div>
    </>
  );
}

interface SettingsFormProps {
  defaultValues: FormData;
  onSubmit: FormSubmitHandler<FormData>;
  hasDPN: boolean;
  showSwitch: boolean;
  templates: { email: MessageTemplateEmail; sms: MessageTemplateSms };
}

function SettingsForm({
  defaultValues,
  onSubmit,
  hasDPN,
  showSwitch,
  templates,
}: SettingsFormProps) {
  const { formatMessage } = useIntl();

  const methods = useFormWithDefaults<FormData>({ defaultValues });
  const [scheduleEnabled, deliveryMethod] = methods.watch([
    "scheduleEnabled",
    "deliveryMethod",
  ]);

  const handleSubmit: SubmitHandler<FormData> = formValues => {
    onSubmit(formValues, { setError: methods.setError });
  };

  return (
    <>
      {methods.formState.errors?.root?.serverError && (
        <Banner type={"error"} dismissible={true}>
          {formatMessage(messages.generalError)}
        </Banner>
      )}

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <Content>
            {showSwitch && (
              <Flex template={["grow", "shrink"]} direction="row" align="start">
                <Heading level={4}>
                  {formatMessage(messages.followUpHeading)}
                </Heading>
                <Controller
                  name={"scheduleEnabled"}
                  render={({ field: { value, onChange } }) => {
                    return <Switch value={value} onChange={onChange} />;
                  }}
                />
              </Flex>
            )}
            {scheduleEnabled && (
              <>
                <Select
                  placeholder={formatMessage(
                    messages.messageSettingsPlaceholder,
                  )}
                  name={formatMessage(messages.messageSettingsSelectName)}
                >
                  <Option value={NotificationDeliveryMethod.EMAIL}>
                    {formatMessage(messages.emailOptionLabel)}
                  </Option>
                  <Option value={NotificationDeliveryMethod.SMS}>
                    {formatMessage(messages.textOptionLabel)}
                  </Option>
                </Select>
                <div className={styles.editContainer}>
                  <MessagePreviewer
                    template={
                      deliveryMethod === "EMAIL"
                        ? templates?.email
                        : templates?.sms
                    }
                    hasDPN={hasDPN}
                  />
                </div>
              </>
            )}
            <div className={styles.footer}>
              <Button
                type="primary"
                size="large"
                submit={true}
                fullWidth={true}
                ariaLabel={formatMessage(messages.messageSettingsSaveAriaLabel)}
                label={formatMessage(messages.messageSettingsSaveLabel)}
                disabled={methods.formState.isSubmitting}
              />
            </div>
          </Content>
        </form>
      </FormProvider>
    </>
  );
}
